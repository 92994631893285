#header {
  padding: 1.5rem 0 1.5rem 0;
  width: 100vw;
  /* max-height: fit-content; */
  height: 50%;
  background-color: slategray;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: Sacramento, cursive;
  color: brown;
}

.proimg {
  margin-left: 5rem;
  width: 22rem;
  height: 22rem;
}

.prodesc {
  margin-right: 5rem;
}
h1 {
  font-size: 10rem;
}

h2 {
  font-size: 7rem;
}
